import React from "react";
import PropTypes from "prop-types";
import {  graphql } from "gatsby";
import Layout from "../components/Layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useWindowSize from '../hooks/useWindowSize';
import newsImage from '../img/boston_news.png'
import { StaticImage } from "gatsby-plugin-image"



// eslint-disable-next-line
 const News = () => {

  const { width } = useWindowSize();


  return (
   <Layout>
      <section className="section">
         
            <div className="container content">
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  <div style={{display: 'flex', flexDirection: (width > 768 ? 'row': 'column-reverse'), gap: '5%'}}>
                      <div style={{width: (width > 768 ? "60%": "100%"), padding: (width > 768 ? "0%": "5% 0%"), display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                          <h1 style={{color: '#E65E27', textAlign: (width>768 ? 'left': 'center'), lineHeight: '1.5', fontSize: (width > 768 ? "1.3em": "1.4em")}}>
                          PharmSight, a Bio-Pharma Consulting Firm, Embarks on Global Expansion Journey with U.S. Subsidiary; Opens New Strategic Hub in Boston
                          </h1>
                          <p style={{textAlign: (width>768 ? 'left': 'center'), width: (width > 768 ? "100%": "80%"),fontSize: (width > 768 ? "0.9em": "0.8em"),fontStyle:'italic'}}>U.S. operations to drive enhanced client engagement, and talent acquisition as part of a broader global expansion strategy—including forthcoming offices in Europe and the Middle East</p>
                          <p style={{textAlign: (width>768 ? 'left': 'center'), width: (width > 768 ? "100%": "80%"),fontSize: (width > 768 ? "0.8em": "0.8em"),color:'#7767DC'}}>February 25, 2025 08:00 AM Eastern Standard Time</p>
                      </div>
                      <div style={{width: (width> 768 ? "35%": "100%"), display: "flex", justifyContent: "center"}}>
                 
                  <StaticImage src={'../img/boston_news.png'} alt="News Image" layout="fixed" objectFit="contain" className="blog-image" width={250}
      height={350} />
                      </div>
                  </div>
                  <div className="sub-section" style={{marginTop:'2rem'}}>
                      <p style={{fontSize:(width>768?'1.2em':'1em')}}>BOSTON--(<a style={{textDecoration: 'underline'}} href="https://www.businesswire.com/portal/site/home/" target="_blank">BUSINESS WIRE</a>)--PharmSight Research & Analytics, a high-impact solutions provider for global biopharma, today announced the expansion of its U.S. operations with the opening of its Boston office under its newly formed entity, PharmSight Inc. The company also announced that co-founder <strong>Geet Sethi</strong> will take charge of PharmSight Inc. as Director of Operations. Bringing 15+ years of biopharma consulting expertise and a robust network across the region, Geet is well-positioned to lead PharmSight's expansion in the States.</p>
                  </div>
                  <div className="sub-section" style={{marginTop:'1rem'}}>
                      <p style={{fontSize:(width>768?'1.2em':'1em')}}>“Our Boston expansion is more than just a geographic milestone—it’s a strategic pivot that reinforces our commitment to transforming how biopharma companies harness data for better outcomes,” said <strong>Abhinav Basia</strong>, Co-founder of PharmSight. “With 75% of our business originating from U.S. clients, opening our Boston office is the logical next step to deepen local partnerships and elevate our service offerings.”</p>
                  </div>
                  <div className="sub-section" style={{marginTop:'1rem'}}>
                      <p style={{fontSize:(width>768?'1.2em':'1em')}}>Since its inception in 2018, PharmSight has partnered with leading global bio-pharma companies to support data driven decision-making with strategic insights. Utilizing proprietary AI/LLM-powered tools and real-world evidence (RWE), PharmSight has published influential studies that are reshaping industry perceptions. PharmSight's tailored solutions—powered by deep market research, advanced technology, and expert teams—help clients overcome strategic challenges ensuring lasting impact.</p>
                  </div>
                  <div className="sub-section" style={{marginTop:'1rem'}}>
                      <p style={{fontSize:(width>768?'1.2em':'1em')}}>Co-founder <strong>Chirag Maheshwari</strong> added, “Our investment in advanced AI and LLM initiatives is revolutionizing the way we empower biopharma decision-making. This year, we saw a 40% growth which confirms that our technology-driven approach is making a difference. As we expand globally and prepare to launch an innovation center focused on AI, we are poised to deliver even greater value to patient care.”</p>
                  </div>
                  <div className="sub-section" style={{marginTop:'1rem'}}>
                      <p style={{fontSize:(width>768?'1.2em':'1em')}}>PharmSight plans to broaden its global footprint by opening new offices in Europe and the Middle East and launching an innovation center dedicated to AI-driven solutions. The firm aims to double its workforce over the next 1-2 years, reaffirming its commitment to accelerating innovation in healthcare. PharmSight remains open to strategic partnerships that support sustainable growth and advance patient care worldwide.</p>
                  </div>
                 
                </div>
              </div>
            </div>
          </section>
    </Layout>
  );
};






export default News
